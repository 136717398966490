@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

:root {
  /* Green Theme 
  --main-color: #0e4749;
  --text-color: #e55812;
  --comp-color: #d7ecff; */

  /* Classic Theme */
  --main-color: #253e5c;
  --card-color: #253e5c; /* #006158; */
  --text-color: #ef476f;
  --comp-color: #dcf2ff;

  /* Royal Theme 
  --main-color: #253e5c;
  --text-color: #dda11d;
  --comp-color: #dcf2ff;
  */

  --toggle-color: "#253e5c";
}

.info-toast {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: solid !important;
  border-radius: 40px !important;
  padding: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.completion-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  text-align: center;
  background: var(--comp-color);
  opacity: 100%;
}

.completion-triangle {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 30px solid var(--comp-color);
  margin: 0 auto;
  margin-top: -1px;
  margin-bottom: 1rem;
  opacity: 100%;
}

.color-nav {
  background-color: var(--main-color);
  opacity: 1;
}
.dark-color-nav {
  background-color: var(--main-color);
}

.red-text {
  color: var(--text-color);
}

.blue {
  background-color: var(--card-color) !important;
  color: white !important;
  opacity: 1;
}
.btn-flat {
  background-color: #008a5a;
  color: white;
}

.button {
  background-color: var(--card-color) !important;
  color: white !important;
}

.home-links {
  color: #212529;
  margin-left: 5px;
}

.read-or-hide {
  color: rgb(140, 140, 140);
  cursor: pointer;
}

.green-text {
  color: #2e7d32;
}

.shape-blob {
  background: #ef476f;
  display: none;

  height: 200px;
  width: 200px;
  border-radius: 30% 50% 40% 40%;
  animation: transform 120s ease-in-out infinite both alternate,
    movement_one 120s ease-in-out infinite both;
  opacity: 0.6;
  position: absolute;
  left: 98%;
  top: 50%;
}

.shape-blob.one {
  background: #26547c;
  display: none;

  height: 500px;
  width: 300px;
  left: -250px;
  top: 700px;
  transform: rotate(-150deg);
  animation: transform 120s ease-in-out infinite both alternate,
    movement_two 180s ease-in-out infinite both;
}

.shape-blob.two {
  background: #ef476f;
  display: none;
  display: none;
  height: 350px;
  width: 350px;
  left: -100px;
  top: -150px;
  transform: rotate(-220deg);
  animation: transform 120s ease-in-out infinite both alternate,
    movement_two 180s ease-in-out infinite both;
}

@media screen and (max-width: 600px) {
  .shape-blob.two {
    background: #ef476f;
    height: 350px;
    width: 350px;
    left: -100px;
    top: -230px;
    transform: rotate(-220deg);
    animation: transform 120s ease-in-out infinite both alternate,
      movement_two 180s ease-in-out infinite both;
  }
}

.shape-blob.three {
  background: #ef476f;
  height: 350px;
  width: 350px;
  left: -300px;
  top: 1900px;
  transform: rotate(-280deg);
  animation: transform 120s ease-in-out infinite both alternate,
    movement_two 180s ease-in-out infinite both;
}
.shape-blob.four {
  background: #26547c;
  height: 350px;
  width: 350px;
  left: 90%;
  top: 2100px;
  transform: rotate(-220deg);
  animation: transform 120s ease-in-out infinite both alternate,
    movement_two 180s ease-in-out infinite both;
}
/*   
  @keyframes transform
  {
      0%,
    100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; } 
     20% { border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%; } 
     40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%; } 
     60% { border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%; } 
     80% { border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%; } 
  }
  
  
  @keyframes movement_one
  {
      0%,
    100% { transform: none; }
     50% { transform: translate(50%, 20%) rotateY(10deg) scale(1.2); }
  }
  
  @keyframes movement_two
  {
      0%,
    500% { transform: none; }
     50% { transform: translate(50%, 20%) rotate(-200deg) scale(1.2);}
  } */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.line {
  border: 2px solid var(--main-color);
  opacity: 1;
  flex-grow: 100;
}

.lineNotFill {
  border: 2px solid #a1a1a1;
  opacity: 1;
  flex-grow: 100;
}

/* a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
} */
/* a,
a:hover {
  text-decoration: none !important;
} */

.bookmarkToggle:focus {
  box-shadow: none !important;
  outline: none !important;
}

.bookmarkToggle.active {
  background-color: inherit;
}
.bookmarkToggle:hover {
  background-color: inherit;
  color: white;
  border-color: white !important;
  box-shadow: 0 0 0 0.23rem rgba(190, 190, 190, 0.5) !important;
}
.content {
  height: 70vh;
}

.crop {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.iconWeight {
  stroke: white;
  stroke-width: 0.3px;
  stroke-linecap: round;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 2px;
}